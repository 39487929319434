@media only screen and (max-width: 1100px) and (min-width: 100px) {
  .only-desktop {
    display: none;
  }
  .only-mobile {
    display: block;
  }
  .logo h2 {
    font-size: 2rem;
  }
  #mobile-menu {
    display: block;
  }
  .applications-main .items img {
    height: 60px;
    width: auto;
  }
  .update-flex {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 20px;
  }

  .navbar-content {
    display: none;
  }
  .login-button {
    display: none;
  }
  .school-profile-name-flex {
    display: flex;
    align-items: center;

    gap: 10px;
  }
  .facilitator-name {
    width: 100% !important;
  }
  .valu-m {
    font-size: 0.8rem !important;
  }
  .absolute-btn {
    position: absolute;
    z-index: 99;
    left: 30%;
  }
  .profile-menu {
    display: none;
  }

  #profile_menu {
    display: block;
  }
  .ass-flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .sidenav {
    display: none;
  }
  .school-profile-name-flex {
    display: flex;
    align-items: center;

    flex-direction: column;
  }
  .profile_menu {
    display: block;
    text-align: end;
  }
  .profile_header {
    display: none;
  }
  .mobile-nav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    max-width: 300px;
    width: 100%;
    background-color: #fff;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.05) 0px 8px 32px;
    overflow-x: hidden;
    transition: 0.5s;
  }
  .mobile-menu-flex {
    background: #fff;
  }
  .mobile-nav a {
    padding: 20px 8px 8px 25px;
    text-decoration: none;
    font-size: 1rem;
    color: var(--paragraph-text);
    font-weight: 600;
    display: block;
    transition: 0.3s;
  }
  .mobile-nav li {
    border-bottom: 1px solid #f1f1f1;
  }
  .main-mobile {
    display: block;
  }
  .mobile-nav a:hover {
    color: #f1f1f1;
  }
  .mobile-menu {
    display: block;
  }
  .mobile-nav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  .profile_header {
    padding: 0px 0px;
  }
  .mobile-menu nav {
    display: flex;
  }
  .only-mobile {
    display: block;
  }
  .only-desktop {
    display: none;
  }
  .mobile-menu nav {
    display: flex;
  }
  .mobile-menu-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }
  .notification-flex {
    display: flex;
    align-items: center;
    gap: 25px;
  }
  .notification-flex li {
    margin-right: 0 !important;
  }
  .after-login-flex {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
  }
  .after-login-flex .user-circle {
    background: #906951;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  }
  .after-login-flex.user-circle {
    flex: 2;
  }
  .address-student h5 {
    font-size: 1.1rem;
    margin-bottom: 0 !important;
  }
  .address-student p {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
  .address-student {
    flex: 1;
  }
  .user-agent-flex {
    display: flex;
    margin: 0 !important;
    padding: 0 !important;
    justify-content: space-between;
  }
  .user-agent-flex span {
    color: #fff;
    margin-bottom: 10px;
  }
  .agent {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 3px;
  }
  .logout {
    background: #f60505;
    border: 1px solid #f60505;
    border-radius: 3px;
  }
  .after-login a {
    background: #f60505;
    display: block;
  }
  .btn {
    padding: 10px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }
  .mobile-menu-flex img {
    height: 30px;
    width: auto;
  }
  .mobile-nav img {
    height: 40px;
    width: auto;
  }
  .close-with-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 10px;
    border-bottom: 1px solid #f1f1f1;
  }
  .close-l a {
    font-size: 1.5rem;
    margin-right: 10px;
  }
  .after-login {
    background: #f6f9f9;
  }
  .breadcrumb-profile {
    background: #f9f9f9;
    margin-top: 0px;
  }
  .school-and-programs ul {
    display: flex;
    overflow-y: scroll;
  }
  .main-sec-heading h1 {
    font-weight: 300;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .main-profile-tabs ul {
    display: flex;
    flex-flow: wrap;
    text-align: center;

    margin: auto;

    gap: 15px;
  }
  .main-profile-tabs ul li {
    display: inline-block;
    margin-right: 20px;
  }
  .school-and-programs ul li a {
    border: 1px solid #f1f1f1;
    padding: 12px 15px;
    border-radius: 5px;
    display: block;
    max-width: 250px;
    /* width: 100%; */
    width: 225px;
  }
  .school-and-programs ul li {
    text-decoration: none;
    list-style-type: none;
    /* padding: 8px; */
    margin-bottom: 15px;
    border-radius: 2px;
  }
  .pagination {
    text-align: center;
  }
  .grid-container1 {
    display: contents;
    grid-template-columns:
      2% 8% 7% 6% 1fr minmax(auto, 115px) minmax(auto, 115px) minmax(70px, 40%)
      0px;
    align-items: center;
    grid-gap: 10px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    grid-auto-rows: minmax(10px, auto);
    grid-auto-columns: minmax(10px, auto);
  }
  .grid-container-flex {
    display: contents;
  }
  .other-flex {
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 25px;
    justify-content: center;
    padding-bottom: 20px;
  }
  .top-bar-contact ul li {
    display: inline-block;
    margin-right: 5px;
  }
  .login-image {
    display: none;
  }
  .register-main {
    padding: 10px;
  }
  .login-main {
    padding: 10px;
  }
  .reset-image {
    display: none;
  }
  .reset-main {
    padding: 10px;
  }
  .main-application-content-flex {
    display: flex;

    flex-direction: column;
    gap: 25px;
  }
  .icon-with-flex-pre-payment {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    gap: 25px;
  }
  .pre-payment-heading-flex {
    flex-direction: column;
  }
  .box-main-box {
    padding: 2px 0px;
  }
  .dro-height {
    overflow-y: hidden !important;
    height: auto;
  }

  /* website responsive start */
  .arrow {
    display: none;
  }
  .single-select-country {
    display: flex;
    flex-direction: column !important;
  }
  .single-select-country .img-holder {
    position: relative;
    display: block;
    max-width: 302px;
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .inner-box {
    margin-bottom: 20px;
  }
  .blog-item.style-two.middle-image {
    margin-bottom: 20px;
  }
  .counter-three-wrap {
    flex-direction: column;
  }
  .copy-right-container p {
    text-align: center;
    font-size: 0.9rem;
    margin-bottom: 20px;
  }
  .header-logo img {
    height: 50px;
    width: auto;
  }
  /* website responsive end */
}

@media (min-width: 992px) {
  .tracking-item {
    margin-left: 2rem;
  }
  .tracking-item .tracking-date {
    position: absolute;
    left: -10rem;
    width: 7.5rem;
    text-align: right;
  }
  .tracking-item .tracking-date span {
    display: block;
  }
  .tracking-item .tracking-content {
    padding: 0;
    background-color: transparent;
  }

  .tracking-item-pending {
    margin-left: 2rem;
  }
  .tracking-item-pending .tracking-date {
    position: absolute;
    left: -10rem;
    width: 7.5rem;
    text-align: right;
  }
  .tracking-item-pending .tracking-date span {
    display: block;
  }
  .tracking-item-pending .tracking-content {
    padding: 0;
    background-color: transparent;
  }
}

@media only screen and (max-width: 600px) {
  .total-notification-dropdown {
    position: absolute;
    padding: 0.5rem 1rem;
    z-index: 999999999;
    box-shadow: 0px 0px 100px rgba(20, 18, 18, 0.25);

    left: -290px !important;
    border-radius: 5px;
    top: 1.5rem;
    max-height: 500px;
    overflow-y: scroll;
    background: #fff;
    width: 25rem;
  }
}
