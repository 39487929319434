@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@400;500;600;700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import url('../css/responsive.css');
@font-face {
  font-family: 'Aileron-Bold';
  src: url('../fonts/Aileron-Bold.otf');
}
@font-face {
  font-family: 'Aileron-Heavy';
  src: url('../fonts/Aileron-Heavy.otf');
}
@font-face {
  font-family: 'Aileron-Light';
  src: url('../fonts/Aileron-Light.otf');
}
@font-face {
  font-family: 'Aileron-Regular';
  src: url('../fonts/Aileron-Regular.otf');
}
@font-face {
  font-family: 'Aileron-SemiBold';
  src: url('../fonts/Aileron-SemiBold.otf');
}
:root {
  --paragraph-text: #2d2d2d;
  --white-color: #fff;
  --heading-color: #031f4b;
  --paragraph-color: #666;
  --primary-color: #14c6ef;
  --third-color: #906951;
  --secondary-color: #1673d8;
}
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

a:hover {
  color: var(--primary-color);
}
a {
  color: var(--primary-color);
  cursor: pointer;
}
.status-ic .badge-view {
  background: var(--primary-color);
  margin-right: 10px;
}
.status-ic .badge-view:hover {
  color: #fff;
  background: #3ed3f5;
}
.status-ic .badge-delete {
  background: #ffbfc4;
  color: #f5182a;
}
.status-ic .badge-delete:hover {
  background: #f0868f;
  color: #fff;
}
ul {
  margin: 0;
  padding: 0;
}
.myapplication-table .table > :not(caption) > * > * {
  text-align: left;
  font-size: 0.9rem;
  padding: 10px 10px;
  color: #1b1b1b;
}

.main-mobile {
  display: none;
}
.school-and-programs li {
  margin-right: 20px;
  display: contents;
}
.mx-inc-200 {
  max-width: 100px !important;
}
.myapplication-table {
  max-height: 500px;
  overflow-y: scroll;
}
.school-and-programs li a:hover {
  color: #fff !important;
}
.applications-main {
  margin-bottom: 20px;
  border: 1px solid #f1f1f1;
}

button {
  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  margin: 0.5em;
  padding: 1em 2em;
}
img {
  max-width: 100%;
  height: auto;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.7s;
  transition-property: all;
}
.update-flex {
  display: flex;
  flex-direction: row;
}
#mobile-menu {
  background: none;
  padding: 10px 15px;
  background: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}
.mobile-menu-open {
  background: #fff;
  padding: 0;
  margin: 0;
}
.mobile-menu-open ul {
  padding: 0;
  margin: 0;
}
.logi-but {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.mobile-menu-open .primary-btn,
.mobile-menu-open .secondary-btn {
  max-width: 150px;
  width: 100%;
}

.mobile-menu-open ul li {
  list-style-type: none;
  text-decoration: none;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}
.mobile-menu-open ul li a {
  display: block;

  padding: 5px 20px;
  width: 100%;
}
img {
  vertical-align: middle;
  border-style: none;
}
/* a:hover {
  color: #e34133;
} */
strong {
  font-weight: 700;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1rem;
  color: var(--paragraph-color);
  line-height: 1.7em;

  background: #ffffff;
  -webkit-font-smoothing: antialiased;

  -moz-font-smoothing: antialiased;
}

.bordered-layout .page-wrapper {
  padding: 0px 50px 0px;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #315a72;
}

button,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none !important;
}
p {
  position: relative;

  line-height: 1.8em;
  color: var(--paragraph-color);
}

h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.6em;
  color: var(--heading-color);
  font-family: 'Aileron-semibold', sans-serif;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

textarea {
  overflow: hidden;
}
/* main css start */
/* topbar css */
.top-bar {
  background-color: var(--primary-color);
}
/* topbar css end */
.top-bar-contact ul li {
  display: inline-block;
  margin-right: 25px;
}

.material-symbols-rounded {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}
.top-bar-contact ul li a i {
  color: #fff;
}
.header .row {
  display: flex;
  align-items: center;
}
.mobile-menu {
  display: none;
}
.top-bar-contact li a {
  display: flex;
  align-items: center;
  line-height: 25px;
}
.top-bar-contact li a .icon {
  margin-right: 10px;
}
.top-bar-contact li a span {
  color: #fff;
}
.top-bar-contact {
  padding: 5px 0px;
}
.top-bar-contact li a:hover {
  color: #e34133;
}
.only-mobile {
  display: none;
}
.only-desktop {
  display: block;
}
#mobile-menu {
  display: none;
}
.navbar-content li {
  display: inline-block;
}
.header-navbar {
  box-shadow: 7px 3px 20px 8px rgba(200, 194, 194, 0.25);
}
.ptb-50 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.sec-heading h2 {
  text-align: center;
  font-weight: 600;
}
.sec-heading {
  margin-bottom: 40px;
  text-align: center;
}
.register-card {
  border: 1px solid #eeeeee;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 1px 0px 0px rgba(27, 31, 36, 0.04);
}
/* .register-card:hover {
  border: none;
} */
.card-center {
  text-align: center;
}
.card-circle {
  width: 100px;
  height: 100px;
  background: var(--primary-color);
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  text-align: center;
  justify-items: center;
  justify-self: center;
  justify-content: center;
}
.card-circle span {
  font-size: 50px;
  color: #fff;
}
.header-logo img {
  height: 60px;
}
.card-heading h3 {
  color: var(--heading-color);
  font-weight: 600;
}
.card-heading p {
  color: #888;
}
.primary-btn {
  max-width: 250px;
  transition: 800ms ease all;
  outline: none;
  color: var(--primary-color);
  border: 2px dotted var(--primary-color);
  font-weight: 500;
}
.btn {
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
.primary-btn:hover {
  background: var(--primary-color);
  color: #fff;
  -webkit-transition-delay: 0s;
  border: 2px dotted var(--primary-color);
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.cell-wrap-text {
  white-space: normal !important;
}

.primary-btn-h {
  max-width: 250px;
  background: var(--primary-color);
  color: #fff;
  border: 2px dotted var(--primary-color);
  font-weight: 500;
}

.primary-btn-h:hover {
  border: 2px dotted var(--primary-color);
  color: var(--paragraph-text);
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.already-register {
  position: relative;
}
.already-register::before {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(30, 30, 30, 0.9);
  width: 100%;
  height: 100%;
  content: '';
}
.already-register-content {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.already-register-content h2 {
  color: #fff;
}
.secondary-btn {
  max-width: 250px;

  color: #fff;
  background: var(--primary-color);

  font-weight: 500;
}

.secondary-btn:hover {
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.mw-150 {
  max-width: 150px;
  width: 100%;
}
.login-already {
  margin-top: 20px;
}
.login-already .btn {
  padding: 10px 5px;
}
.footer {
  padding: 20px 20px;
  background: #eee;
}

.login-button {
  display: flex;
  gap: 25px;
}
/* register-as-student */
.register-student-form {
  padding: 20px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.register-student {
  position: relative;
  background-repeat: repeat;
  background-image: url('../images/background_repeat.jpg');
}
.register-student::before {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(24, 198, 238, 0.44);
  content: '';
  height: 100%;
  width: 100%;
}
.register-student-form .form-control {
  background: #fff;
}
.register-student-form .form-control:focus {
  box-shadow: none;
  background: #fff;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.login-student-form label {
  font-size: 0.8rem;
}
/* end */
.login-student-form label a {
  color: var(--primary-color);
  margin-left: 2px;
}
.login-student-form .form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.login-student-form.form-select label {
  font-size: 0.8rem;
}
.login-student-form .form-select:focus {
  box-shadow: none;
  background: #fff;
}
.login-student-form .form-select {
  background: #fff;
}
.create-button {
  text-align: center;
  margin-top: 10px;
}
.terms p {
  font-size: 0.8rem;
  color: #999;
  margin-left: 5px;
  display: block;
}
/* flag */
.terms a {
  color: var(--primary-color);
  text-decoration: underline;
}
.login-student-form h3 {
  margin-bottom: 5px;
}
.login-student-form p {
  color: #999;
  font-size: 0.8rem;
}
.coun .form-control {
  background: #fff;
  height: 57px !important;
}
/* login */
/* login-as-student */
.login-student-form {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
}
.login-student {
  background: var(--white-color);
}
.login-student-form .form-control {
  background: #fff;
}
.login-student-form .form-control:focus {
  box-shadow: none;
  background: #fff;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.login-student-form label {
  font-size: 0.8rem;
}
/* end */
.login-student-form label a {
  color: var(--primary-color);
  margin-left: 2px;
}
.login-student-form .form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.login-student-form.form-select label {
  font-size: 0.8rem;
}
.login-student-form .form-select:focus {
  box-shadow: none;
  background: #fff;
}
.login-student-form .form-select {
  background: #fff;
}
.create-button {
  text-align: center;
  margin-top: 10px;
}
.terms p {
  font-size: 0.8rem;
  color: #999;
  margin-left: 5px;
  display: block;
}
/* flag */
.terms a {
  color: var(--primary-color);
  text-decoration: underline;
}
.login-student-form h3 {
  margin-bottom: 5px;
}
.login-student-form p {
  color: #999;
  font-size: 0.8rem;
}
.login-flex {
  display: flex;
}
.login-main {
  flex: 1;
  padding: 50px;
}
.login-image {
  flex: 2;
  position: relative;
}
.login-image img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: fixed;
  min-height: 100%;
}
.login-image::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}
.mw-100 {
  width: 100%;
}
.noaccount {
  margin-top: 10px;
}
.noaccount h5 {
  margin-bottom: 20px;
}
.noaccount a {
  color: var(--primary-color);
  margin-bottom: 10px;
  display: block;
  text-decoration: underline;
}
.privacy-link {
  margin-top: 50px;
}
.privacy-link a {
  color: #1673d8;
  margin-bottom: 15px;
  display: block;
}
/* reset-password */
.reset-password-form {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
}
.reset-password {
  background: var(--white-color);
}
.reset-password-form .form-control {
  background: #fff;
}
.reset-password-form .form-control:focus {
  box-shadow: none;
  background: #fff;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.reset-password-form label {
  font-size: 0.8rem;
}
/* end */
.reset-password-form label a {
  color: var(--primary-color);
  margin-left: 2px;
}
.reset-password-form .form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.reset-password-form.form-select label {
  font-size: 0.8rem;
}
.reset-password-form .form-select:focus {
  box-shadow: none;
  background: #fff;
}
.reset-password-form .form-select {
  background: #fff;
}
.create-button {
  text-align: center;
  margin-top: 10px;
}
.terms p {
  font-size: 0.8rem;
  color: #999;
  margin-left: 5px;
  display: block;
}

.terms a {
  color: var(--primary-color);
  text-decoration: underline;
}
.reset-password-form h3 {
  margin-bottom: 5px;
}
.reset-password-form p {
  color: #999;
  font-size: 0.8rem;
}
.reset-flex {
  display: flex;
}
.reset-main {
  flex: 1;
  padding: 50px;
}
.reset-image {
  flex: 2;
  position: relative;
}
.reset-image img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: fixed;
  min-height: 100%;
}
.reset-image::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}
.mw-100 {
  width: 100%;
}
.reset-password-form label {
  margin-bottom: 10px;
}
.link {
  color: var(--primary-color);
  margin-left: 2px;
}
/*register agent  */
/* register-as-student */
.register-agent-form {
  padding: 30px 25px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.register-agent-content {
  text-align: center;
}
.register-agent-content h3 {
  font-size: 2rem;
  margin-bottom: 20px;
}
.register-agent {
  background: #f8f9fe;
}
.register-agent-form .form-control {
  background: #fff;
}
.register-agent {
  position: relative;

  background-repeat: repeat;
}
.register-agent::before {
  position: absolute;
  top: 0;
  left: 0;

  content: '';
  height: 100%;
  width: 100%;
}
.register-agent-form .form-control:focus {
  box-shadow: none;
  background: #fff;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.login-agent-form label {
  font-size: 0.8rem;
}
.register-agent-form .drop_box {
  margin: 10px 0;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #a3a3a3;
  border-radius: 5px;
}
.drop_box .btn {
  padding: 8px 32px;
}
.register-agent-form .drop_box h4 {
  font-size: 16px;
  font-weight: 400;
  color: #2e2e2e;
}

.register-agent-form .drop_box p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #a3a3a3;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.register-agent-form .form input {
  margin: 10px 0;
  width: 100%;
  background-color: #e2e2e2;
  border: none;
  outline: none;
  padding: 12px 20px;
  border-radius: 4px;
}
.agent-register-main-button {
  text-align: center;
}
.register-agent-form .form-select:focus {
  box-shadow: none;
  background: #fff;
}
/* register agent end */

/*  */
/* profile start */
.wrapper {
  background-color: #f6f6f6;
  height: 100%;
}
#profile_menu {
  display: none;
}
.profile-menu {
  position: relative;
  z-index: 2;
}
.profile-menu ul li {
  display: inline-block;
  margin-right: 25px;
}

.profile_header {
  padding: 20px 0px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  background: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}
.label-featu {
  padding: 10px 0px;
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--paragraph-text);
}
.profile_header .row {
  display: flex;

  align-items: center;
  justify-content: center;
}
.label-heading h5 {
  color: var(--paragraph-text) !important;
}
.label-heading {
  border: 1px solid #f1f1f1;
  padding-top: 10px;
  margin-top: 10px;
  padding: 15px;
  margin-bottom: 20px;
}
.notification-icon ul li {
  margin-right: 25px;
  display: inline-block;
}
.pull-right {
  text-align: left;
}
.profile-menu ul li a {
  color: var(--paragraph-text);
  font-weight: 600;
  font-size: 18px;
}
.active {
  color: var(--primary-color);
}
.notification-icon ul li a {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  border-radius: 50%;
}
.notification-icon ul li a i {
  display: flex;
  text-align: center;
  height: 100%;
  align-items: center;
  justify-content: center;

  color: var(--heading-color);
}
.profile_header .nav {
  align-items: center;
}
.breadcrumb-profile {
  background: #f9f9f9;
  margin-top: 90px;
}
.breadcrumb-profile .breadcrumb-item {
  padding-top: 10px;
}
.breadcrumb-item a {
  color: var(--primary-color);
}
.breadcrumb-item-active {
  font-weight: 600;
  color: var(--paragraph-text);
}

.ptb-30 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.main-sec-heading h1 {
  font-weight: 300;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1.8rem;
  margin-bottom: 10px;
}
.main-profile-tabs {
  border-bottom: 1px solid #abb8ad;
}
.main-profile-tabs ul li {
  display: inline-block;
  margin-right: 40px;
}

.main-profile-tabs ul li a i {
  color: var(--primary-color);
  margin-right: 5px;
}
.close-l i {
  font-size: 1rem;
  padding: 5px;
  background: #906951;
  color: #fff;
  border-radius: 2px;
}
.register-main {
  flex: 2 1;
  padding: 50px;
}
.main-profile-tabs ul li a {
  font-size: 1rem;
  color: var(--paragraph-text);
  position: relative;
} /* profile-end */

.error {
  font-size: 0.8rem !important;
  font-weight: 500 !important;
}
.tab-active {
  position: relative;
  background: #fff;
  padding: 10px;
  border-bottom: 2px solid var(--primary-color);
}
.pro-active {
  background: fff;
}
.personal-information-box {
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.personal-information-box h2 {
  font-size: 1.5rem;
  position: relative;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px;
}
.personal-information-box span {
  color: var(--primary-color);
  /* font-weight: 600; */
  margin-bottom: 1px;
  display: block;
  font-size: 0.8rem;
}
.clearfix {
  clear: both;
}

.personal-information-box.form-control {
  background: #fff;
}
.personal-information-box .form-control:focus {
  box-shadow: none;
  background: #fff;
}
.personal-information-box.form-select:focus {
  box-shadow: none;
  background: #fff;
}

.personal-information-profile {
  padding-bottom: 30px;
}
.save-button {
  padding-top: 20px;
  text-align: center;
}
select.form-control {
  -webkit-appearance: menulist !important;
  -moz-appearance: menulist !important;
  -ms-appearance: menulist !important;
  -o-appearance: menulist !important;
  appearance: menulist !important;
}
/* profile-footer */
.profile-footer {
  background: var(--primary-color);
  padding: 10px 10px;
}
.profile-social-icon ul li {
  display: inline-block;
  margin-right: 25px;
}
.profile-privacy ul li {
  display: inline-block;
  margin-right: 15px;
}
.profile-privacy ul li a {
  color: #fff;
  font-weight: 600;
  font-size: 0.8rem;
}
.profile-social-icon ul li a i {
  color: #fff;
  font-size: 1.2rem;
}
.copyright-profile {
  text-align: center;
}
.copyright-profile p {
  color: #fff;
}
.personal-information-box h5 {
  margin-bottom: 20px;
  font-size: 1.1rem;
  color: var(--primary-color);
  font-weight: 600;
}
.middle-form {
  padding-top: 20px;

  border-bottom: 1px solid #f1f1f1;
}
.middle-form h5 {
  font-weight: 600;
  color: var(--paragraph-text);
}
.school-attend-flex {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}
.add-school-attend {
  padding: 10px 10px;
  align-items: center;
  border: 1px solid var(--primary-color);
  display: flex;
  justify-content: space-between;
}
.personal-information-box h2 {
  margin-bottom: 30px;
}
.give-exam {
  padding-top: 10px;
}
.main-label {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 600;
  color: var(--paragraph-text);
}
.personal-information-box .form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
/* .personal-information-box .form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 5.5em !important;
  margin-left: -2.5em;
}
.personal-information-box .form-check-input {
  height: 1.5em;
  margin-top: 0.25em;
} */
.personal-information-box .form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}
.text-danger {
  font-size: 0.8rem;
  font-weight: 500;
}
.visa label {
  font-weight: 500;
  color: var(--paragraph-text);
}
.user-dropdown {
  position: relative;
}
.user-dropdown-f {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  max-width: 500px;
  background: #fff;
  height: 100%;
  z-index: 9;
}

/* end prifle footer */

.notification-icon li ul {
  flex-direction: column;
  position: absolute;
  background-color: white;
  align-items: flex-start;
  transition: all 0.5s ease;
  width: 15rem;
  right: 1.5rem;
  top: 5.5rem;
  border-radius: 0.325rem;
  gap: 0;
  padding: 0.5rem 0rem;

  z-index: 999;
  box-shadow: 0px 0px 100px rgba(20, 18, 18, 0.25);
}

.notification-icon ul li:hover > ul,
.notification-icon ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: flex;
}

.material-icons-outlined {
  color: #888888;
  transition: all 0.3s ease-out;
}

.material-icons-outlined:hover {
  color: #ff9800;
  transform: scale(1.25) translateY(-4px);
  cursor: pointer;
}

.profile {
  height: 3rem;
  width: auto;
  cursor: pointer;
}

.sub-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.725rem;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
}

.sub-item:hover {
  background-color: rgba(232, 232, 232, 0.4);
}

.sub-item:hover .material-icons-outlined {
  color: var(--primary-color);
  transform: scale(1.08) translateY(-2px);
  cursor: pointer;
}
.sub-item a {
  width: 100% !important;
  display: block !important;
}
.sub-item:hover p {
  color: #000;
  cursor: pointer;
}

.sub-item p {
  font-size: 0.85rem;
  color: #888888;
  font-weight: 500;
  margin: 0.4rem 0;
  flex: 1;
}
.user-circle {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;

  align-items: center;
  text-align: center;
  color: #fff;
  font-weight: 700;
  background: var(--third-color);
  border-radius: 50%;
}
.user-circle-1 {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;

  align-items: center;
  text-align: center;
  color: #fff;
  font-weight: 700;
  background: var(--third-color);
  border-radius: 50%;
}
.dropuser ul li {
  margin-right: 0 !important;
}
.sub-item span {
  font-weight: 700;
  color: var(--paragraph-text);
}
.sub-item {
  border-bottom: 1px solid #f1f1f1;
}
.sub-item p {
  font-size: 1rem;
  color: var(--paragraph-text);
}
.sub-item p:hover {
  color: var(--primary-color);
}
.notification-icon li:hover {
  cursor: pointer;
}
.wrapper {
  min-height: calc(100vh - 195px);
}
.active {
  color: var(--primary-color) !important;
}
/* alerts css */
.toast {
  position: fixed;
  top: 75px;
  right: 30px;
  border-radius: 12px;
  z-index: 999;
  background: #fff;
  padding: 20px 35px 20px 25px;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transform: translateX(calc(100% + 30px));
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
}

.toast.active {
  transform: translateX(0%);
}

.toast .toast-content {
  display: flex;
  align-items: center;
}

.toast-content .check {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 35px;
  background-color: #4070f4;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
}

.toast-content .message {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.message .text {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}

.message .text.text-1 {
  font-weight: 600;
  color: #333;
}

.toast .close {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 5px;
  cursor: pointer;
  opacity: 0.7;
}

.toast .close:hover {
  opacity: 1;
}

.toast .progress-success,
.toast .progress-danger {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
}

.toast .progress-success:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: red;
}
.toast .progress-danger:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: red;
}

.toast .progress-danger:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: green;
}
.progress-danger.active:before {
  animation: progress 5s linear forwards;
}
.progress-success.active:before {
  animation: progress 5s linear forwards;
}
.toast .progress-success:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: green;
}
.toast .progress-danger:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: green;
}
@keyframes progress {
  100% {
    right: 100%;
  }
}

.toast.active ~ button {
  pointer-events: none;
}
.pb-50 {
  padding-bottom: 50px;
}
.main-f {
  font-size: 0.81rem;
  color: var(--primary-color);
  font-weight: 700;
}
.students-table .table > :not(caption) > * > * {
  padding: 1rem 0.5rem;
  background-color: none !important;
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.students-table .table-hover > tbody > tr > * {
  background: none !important;
  color: var(--bs-table-hover-color);
  font-size: 0.8rem !important;
}
.action-flex {
  display: flex;
  justify-content: space-between;
}
.action-flex a {
  margin-right: 5px;
  background: var(--primary-color);
  padding: 10px 10px;
}
.active-stud {
  background: #ff832b;
  padding: 2px 10px;
  border: 1px solid #ff832b;
  border-radius: 3px;
  font-size: 0.7rem;
  font-weight: 700;
  color: #fff;
}
.pending-stud {
  background: #f1c21b;
  padding: 2px 10px;
  border: 1px solid #f1c21b;
  border-radius: 3px;
  font-weight: 700;
  font-size: 0.7rem;
  color: #fff;
}
.active-view {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 2px 10px;
  border-radius: 3px;
  font-weight: 700;
  font-size: 0.7rem;
  color: #fff;
}
.reject-stud {
  background: #da1e28;
  padding: 2px 10px;
  border: 1px solid #da1e28;
  border-radius: 3px;
  font-weight: 700;
  font-size: 0.7rem;
  color: #fff;
}
.editor-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}
.btn-view {
  background: var(--primary-color);
  padding: 5px 10px;
  color: #fff !important;
  border-radius: 3px;
}
.rich span {
  color: #000 !important;
}
.rich label {
  color: #000 !important;
}
.ag-header-row.ag-header-row-column {
  background: #14c6ef !important;
}
.ag-header-cell-text {
  color: #fff;
}
.ag-icon.ag-icon-menu {
  color: #fff !important;
}
.approved-stud {
  background: #e6f8eb;

  padding: 2px 10px;
  border: 1px solid #e6f8eb;
  border-radius: 3px;
  font-weight: 700;
  font-size: 0.8rem;
  color: #00d775 !important;
}
.active-view:hover {
  color: #fff;
}
.scho-pro .form-control {
  margin-bottom: 20px;
}
.add-student-btn {
  text-align: end;
  width: 100%;
}
.ptb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.personal-information-box h4 {
  padding-bottom: 20px;
  font-size: 1.2rem;
  color: var(--primary-color);
}
.text .form-control {
  height: 100% !important;
}
.circle-image-table {
  height: 50px;
  width: auto;
  object-fit: contain;
}
.file-upload label {
  padding: 8px 0px;
}

.file-upload {
  margin-bottom: 20px;
}
.school-and-programs ul {
  display: flex;
  align-items: center;
  gap: 10px;
}
.similar-card-content a :hover {
  color: var(--primary-color) !important;
}
.ass-flex {
  display: flex;
  padding: 20px 0px;
  /* justify-content: space-between; */
  align-items: center;
  gap: 20px;
}
.ass-flex .btn-assign {
  background: #f9f9f9;
  color: #131313;
  font-weight: 600;
  width: 180px;

  padding: 10px 20px;
}
.school-and-programs ul li {
  text-decoration: none;
  list-style-type: none;
  display: contents !important;
  border-radius: 2px;
}
.setting-flex {
  display: flex;
  gap: 25px;
}
.my-custom-react-select__menu {
  z-index: 2;
}
.school-and-programs ul li {
  padding: 10px 10px;
}
.school-and-programs ul li a {
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  font-size: 0.9em;
  padding: 2px 5px;
  text-decoration: none;

  text-align: center;
}
.school-and-programs ul li a:hover {
  background: #14c6ef;
  color: #fff;
}
.ag-pinned-right-header {
  border: 1px solid #14c6ef !important;
}
.active-tab {
  background: #14c6ef;
  color: #fff;
}
.school-and-programs ul li a i {
  margin-right: 5px;
}
input[type='file']::file-selector-button {
  border: 2px solid #14c6ef;
  padding: 0.5em 0.8em;
  border-radius: 0.2em;
  background-color: #14c6ef;
  color: #fff;
  transition: 1s;
}

input[type='file']::file-selector-button:hover {
  background-color: #59daf8;
  border: 2px solid #59daf8;
  cursor: pointer;
}
/* school profile */
.single_school_profile {
  background: #fff;
}
.school-profile-name-flex {
  display: flex;
  align-items: center;
  gap: 55px;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #14c6ef !important;
}
.school-user-circle {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.school-user-circle img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}
.school-profile-name-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.school-user-circle h2 {
  font-size: 1.5rem;
}
.school-information ul li {
  display: inline-flex;
  margin-right: 25px;
  font-size: 0.875rem;
  align-items: baseline;
  flex-wrap: nowrap;
  color: #888;
}
.school-information ul li i {
  margin-right: 10px;
}
.school-information h2 {
  font-size: 1.5rem;
  margin-bottom: 5px;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none;
  border: none !important;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 50px !important;
  width: 60px;
}
.image-gallery-thumbnail:hover {
  outline: none;
  border: none !important;
}
.image-gallery-icon.image-gallery-play-button {
  display: none;
}
.main-school-single-view {
  background: #fff;
}
.single-school-tabs {
  border-bottom: 1px solid #f1f1f1;
}

.single-school-tabs ul li {
  display: inline-block;
  margin-right: 55px;
  cursor: pointer;
}
.single-school-tabs ul li.active {
  border-bottom: 3px solid #14c6ef;
}
.overview-content {
  border: 1px solid #f1f1f1;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.overview-content h3 {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px;
  font-size: 1.5rem;
}
.overview-content p {
  color: #888;
  font-size: 1rem;
}
.single-school-why h3 {
  font-size: 1.5rem;
  color: #14c6ef;
  padding: 5px 0px;
}
.single-school-why ul li {
  font-size: 0.9rem;
  margin-bottom: 10px;
}
.features-content {
  border: 1px solid #f1f1f1;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.features-content h3 {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px;
  font-size: 1.5rem;
}
.accordian-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 15px 10px;
}
.accordian-content {
  border: 1px solid #f1f1f1;
  padding: 10px;
  border-radius: 5px;
}
.accordian-container h5 {
  font-weight: 100;
  font-size: 1rem;
  color: var(--paragraph-text);
}
.accordian-container i {
  font-size: 0.8rem;
}
.location-content {
  border: 1px solid #f1f1f1;
  padding: 30px;
  border-radius: 5px;
}
.location-content h3 {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.accordion-content p {
  font-size: 0.9rem;
}
.map-view iframe {
  border-radius: 5px;
}
.card-flex {
  border: 1px solid #f1f1f1;
  padding: 10px;
}
.sidebar-card h5 {
  margin-bottom: 10px;
  font-size: 1rem;
  color: var(--heading-color);
}
.sidebar-card {
  margin-bottom: 20px;
}
.founded h5 {
  color: #888;
  font-size: 0.8rem;
}
.year-b h5 {
  color: var(--paragraph-text);
  font-size: 0.9rem;
}
.main-flex-card {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #f1f1f1;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
}
.single-school-sidebar {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.cost-flex {
  display: flex;
  gap: 25px;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}
.fees-content h5 {
  margin: 0 !important;
  font-size: 1rem;
}
.icon-cost img {
  height: 40px;
  width: auto;
}
.fees-content h6 {
  font-size: 0.8rem;
  margin-bottom: 5px;
  color: #888;
}
.line {
  background: #14c6ef;
  width: 100%;
  height: 5px;
}
.aca {
  flex: 1;
}
.aca h5 {
  color: #888;
  font-size: 0.8rem;
}
.line {
  flex: 2;
}
.school-profile-photos img {
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.slider-single {
  width: 100% !important;
}
.school-profile-photos {
  position: relative;
}

.absolute-btn {
  position: absolute;
  z-index: 99;
  right: 0;
}
.btn-view {
  background: #14c6ef;
}
.btn-view:hover {
  background: #52d9f7;
  border: 1px solid #14c6ef;
}
.view-photos {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100%;
  z-index: 999;
  padding: 100px 40px;
  background: rgba(0, 0, 0, 0.9);
}
.view-photos .absolute-btn {
  display: none;
}
.close-ban i {
  color: #fff;
  font-size: 25px;
}
.close-ban {
  cursor: pointer;
  z-index: 9999;
  position: absolute;
  right: 50px;
  top: 50px;
}
.slick-next {
  right: 25px !important;
}
.slick-prev {
  left: 25px !important;
  z-index: 999;
}

.slick-dots li button:before {
  margin-top: 5px;
  background-color: transparent;
  border: 1px solid #14c6ef;

  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
}
.closebtn a {
  background: var(--third-color);
  color: #fff !important;
  padding: 2px 8px !important;
  display: block;
}
.badge-draft {
  background: #fff5e8;

  border-radius: 2px;
  color: #f99521 !important;
}
.badge-edit {
  background: #ecfafe;
  color: #14c6ef !important;
  border-radius: 2px;
}
.badge-trash {
  background: #fbdada;
  color: #ea3333 !important;
  border-radius: 2px;
}

.badge-publish {
  background: #fdd36a;
  border-radius: 2px;
}
.add-student-btn2 {
  display: flex;
  gap: 15px;
}
.badge-draft:hover,
.badge-edit:hover,
.badge-trash:hover,
.badge-publish:hover {
  color: #fff;
}

.really-fixed {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);

  line-height: 60px;
  width: 100%;
  height: 100%;
  z-index: 999;

  text-align: center;
}
.modal-confirm {
  max-width: 500px;

  text-align: center;
  margin: 15% auto;

  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
  border: none !important;
  background: var(--third-color);
  padding: 5px 8px !important;
  border-radius: 2px;
  color: #fff;
}
.modal-confirm .modal-body {
  color: #999;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}
.modal-confirm .modal-footer a {
  color: #999;
}
.modal-confirm .icon-box {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}
.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  text-align: center;
}
.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}
.modal-confirm .btn-secondary {
  background: #c1c1c1;
}
.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}
.modal-confirm .btn-danger {
  background: #f15e5e;
}
.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #ee3535;
}
.apply-button {
  padding: 10px 0px;
}
.apply-button .primary-btn-h {
  max-width: 345px !important;
  width: 100%;
}
/* .main-rel-bg {
  background: #000;
  position: absolute;

  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
} */
.single-school-sidebar h4 {
  margin-bottom: 20px;

  position: relative;
  font-size: 1.2rem;
}
.badge-open {
  padding: 12px 35px;
  background: #49e0a1;
  color: #fff !important;
  max-width: 450px;
  display: block;
  width: 100%;
  cursor: pointer;
}
.program-heading-intakes-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.program-heading-intakes-flex::after {
  position: absolute;
  left: 0;
  bottom: 5px;
  margin-bottom: 5px;
  content: '';
  height: 1px;
  width: 100%;

  background: #f1f1f1;
}
.rich span {
  /* font-weight: normal !important; */
}
.status-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.minimum-education-card {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  padding: 20px;
  /* height: 160px; */
}
.minimum-education-card h5 {
  font-size: 1rem;
  margin-bottom: 20px;
  position: relative;
}
.minimum-education-card h5::after {
  position: absolute;
  left: 0;
  bottom: -10px;
  margin-bottom: 5px;
  content: '';
  height: 1px;
  width: 100%;

  background: #f1f1f1;
}
.minimum-education-card h6 {
  font-size: 1rem;
  color: #777;
  margin-bottom: 20px;
  line-height: 25px;
  font-weight: 200;
  font-family: 'Aileron-Regular';
}

.similar-program-card {
  display: flex;
  align-items: start;
  gap: 45px;
}
.similar-card-deadline-flex {
  display: flex;
  gap: 50px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.similar-program-icon {
  width: 60px;
  height: 60px;
  background: rgb(20, 198, 239, 0.7);
  margin: 20px auto;
  border-radius: 5px;
  padding: 0px 20px;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  text-align: center;
  justify-items: center;
  justify-self: center;
  justify-content: center;
}
.similar-program-icon i {
  color: #fff;
  font-size: 2rem;
}
.similar-card-content h3 {
  margin-bottom: 20px;
}
.similar-card-deadline-flex h5 {
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #999;
  font-family: 'Aileron-Regular';
}
.similar-card-deadline-flex h6 {
  font-size: 1rem;
  font-family: 'Aileron-SemiBold';
}
.similar-program-single h3 {
  padding: 10px 0px;
}
.similar-program-card {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
}
.num a {
  display: contents !important;
}
.num {
  font-weight: 600;
  color: #000;
}
.contact-form {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 25px;
  border-radius: 5px;
}
.sub-item a {
  display: inline-flex !important;
  align-items: center;
  gap: 10px;
  box-shadow: none !important;
  width: 0 !important;
  height: 0 !important;
}

.sub-item a:hover {
  color: var(--primary-color);
}
.view-single-box {
  border: 1px solid #f1f1f1;
  background: #fff;
  padding: 10px;
}
.view-card-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.view-card-flex h3 {
  font-size: 1rem;
  margin-bottom: 10px;
}
.view-card-flex h4 {
  font-size: 0.9rem;

  color: #888;
}
.heading-single-education h2 {
  font-size: 1rem;
  margin-bottom: 5px;
}
.heading-single-education {
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}
.heading-single-education span {
  color: #888;
  font-size: 1.2rem;
  font-weight: 500;
}
.pagination {
  padding: 20px 0px;
}
.pagination.active {
  background-color: #14c6ef !important;
}
.pagination .active > .page-link,
.page-link.active {
  z-index: 3;
  color: #fff !important;
  background-color: #14c6ef !important;
  border-color: #14c6ef !important;
}
.single-program-overview-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 55px;
}
.personal-information-box .form-group {
  margin-bottom: 10px;
}
.add-requirement-box {
  border: 1px dashed #14c6ef;
  margin: 25px 0px;
  padding: 10px 0px;
  border-radius: 5px;
}
.add-requirement-box .apply-button {
  text-align: center;
}
.admission-requirement-flex-b {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
  justify-content: space-between;
}
.overview-content .admission-requirement-flex-b h3 {
  border: none !important;
}
.single_school_profile h2 {
  text-transform: capitalize;
}
.program-main-heading-single h2 {
  padding: 25px 0px;
  text-transform: capitalize;
}
.bor {
  position: relative;
}
.bor h1 {
  margin-bottom: 10px;
}
.bor h1::before {
  position: absolute;
  bottom: -15px;
  margin-bottom: 15px;
  left: 0;
  background: #f1f1f1;
  width: 100%;
  height: 1px;
  content: '';
}
.big {
  font-size: 1.5rem !important;
  display: block;
  margin: 10px 0px;
  color: var(--primary-color);
  font-weight: 400;
}
.permissions-box {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 20px;
}
.check-all-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .single-roles-table table thead tr {
  display: flex;
  justify-content: space-between;
} */
.single-roles-table table tr td {
  text-align: center;
}
.single-roles-table table tr .success {
  color: #24a148;
  font-size: 1.5rem;
}
.single-roles-table table tr .close {
  color: red;
  font-size: 1.5rem;
}
.single-roles-table table thead {
  background: var(--primary-color);
  color: #fff;
}
.update-password h3 {
  margin-bottom: 20px;
  font-size: 1.5rem;
}
.update-password {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  padding: 20px;
  border-radius: 5px;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
}
.btn-rejected {
  background-color: #f15a28;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;

  height: 40px;
  line-height: 35px;
  outline: 0;
  overflow: hidden;
  padding: 0 15px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  z-index: 9;
  border: 0;
}
.btn-rejected:hover {
  background: #f76636;
  color: #fff;
}
.btn-approve {
  background-color: #00d775;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;

  height: 40px;
  line-height: 35px;
  outline: 0;
  overflow: hidden;
  padding: 0 15px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  z-index: 9;
  border: 0;
}
.btn-approve:hover {
  background: #15e286;
  color: #fff;
}
.btn-approve i,
.btn-rejected i {
  margin-right: 10px;
}
.heading-with-flex {
  display: flex;
  gap: 50px;
}
.card-main-box {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  padding: 20px;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 50px;
}
.card-main-box .drop_box {
  margin: 10px 0;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #a3a3a3;
  border-radius: 5px;
}
/* search */
.search-box .form-search {
  font: bold 13px sans-serif;
  max-width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}
.dro-height {
  overflow-y: hidden !important;
  height: 115px !important;
}
.search-box .form-search input {
  width: 60%;
  box-sizing: border-box;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  background-color: #ffffff;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding: 13px 15px 13px 20px;
  border: 1px solid #b6c3cd;
  border-right: 0;
  color: #4e565c;
  border-radius: 5px;
  outline: none;
  -webkit-appearance: none;
}

.search-box .form-search button {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  background-color: var(--primary-color);
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
  color: #ffffff;
  padding: 15px 22px;
  margin-left: -4px;
  cursor: pointer;
  border: none;
  outline: none;
}

.search-box .form-search i {
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 16px;
  color: #80a3bd;
}
.css-t3ipsp-control:hover {
  border-color: #14c6ef !important;
}
.eligibility-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 15px;
}
.eligibility-flex h5,
.eligibility-flex i {
  color: var(--primary-color);
}
.eligibility-box {
}
.eligibility-form label {
  margin-bottom: 10px;
}
.eligibility-button-flex {
  display: flex;
  justify-content: space-between;
}
.search-none {
  display: none;
}
.search-block {
  display: block;
}
.apply-filters {
  max-width: 250px;
  transition: 800ms ease all;
  outline: none;
  background: var(--primary-color);
  font-weight: 500;
  color: #fff;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  margin-bottom: 10px;
}
.login-student-form {
  position: relative;
}
#show_pas {
  position: relative;
}
.field-icon {
  float: right;
  bottom: 20px;
  right: 12px;
  font-size: 0.8rem;
  position: absolute;
}

.apply-filters:hover {
  color: var(--primary-color);
  -webkit-transition-delay: 0s;
  border: 2px dotted var(--primary-color);
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.eligibility-form {
  margin-bottom: 30px;
  border: 1px solid #f1f1f1;
  padding: 10px 10px;
  border-radius: 5px;
}
.inc label {
  padding-top: 0 !important;
}

input[type='range'] {
  -webkit-appearance: none;
  background-color: black;
  height: 2px;
}
input[type='range']::-webkit-slider-thumb {
  border-radius: 40px;
  background: var(--primary-color);
}

/* Tool Tip */

input[type='range']:focus {
  outline: none;
}
.school-and-programs {
  padding: 10px 0px;
  border-radius: 5px;
}
.school-filter .form-select {
  min-width: 100px;
  width: 150px;
}
.school-filter .form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
}
.similar-card-content h5 {
  margin-bottom: 5px;
}
.location-map {
  margin-bottom: 10px;
}
.location-map i {
  color: #888;
  margin-right: 10px;
}
.apply-flex {
  display: flex;
  justify-content: space-between;
}
.similar-program-single {
  margin-top: 15px;
}
.school-logo-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.school-logo img {
  height: 80px;
  width: auto;
  object-fit: contain;
}
.school-logo {
  flex: 1;
}
.school-name {
  flex: 2;
}
.school-name h4 {
  font-size: 1rem;
  font-family: 'Aileron-Light';
  color: #888;
}
.school-box-container {
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 5px;
  margin: 10px 0px;
}
.grid-container {
  display: grid;
  grid-template-columns:
    2% 8% 7% 6% 1fr minmax(auto, 115px) minmax(auto, 115px) minmax(70px, 40%)
    0px;
  align-items: center;
  grid-gap: 10px;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  grid-auto-rows: minmax(10px, auto);
  grid-auto-columns: minmax(10px, auto);
}
.grid-box {
  background: #fff;
  margin-bottom: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.items {
  text-align: center;
}
.application-progress-flex {
  position: sticky;
  top: 100px;
}
.unpaid-applications {
  background: var(--third-color);
  padding: 10px;
  border-radius: 5px;
}
.unpaid-applications h6 {
  color: #fff;
}
.item-flex {
  color: var(--primary-colors);
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}
.item-flex i {
  color: #14c6ef;
}
.item-flex p {
  text-align: center;
}

.sale-price {
  text-decoration: line-through;
  color: #df2e38;
}
.grid-container-flex {
  padding: 12px 18px;
  display: grid;
  grid-template-columns: 1fr auto;
  -webkit-box-align: center;
  align-items: center;
  row-gap: 20px;
}
.other-flex {
  display: flex;
  align-items: center;
  gap: 25px;
}
.items p {
  font-size: 0.8rem;
}
.items label {
  display: inline-flex;
}
.items label span {
  color: red;
  margin-left: 4px;
  display: block;
  font-size: 0.7rem;
  font-weight: 700;
  align-items: center;
}
.other-flex p {
  line-height: 6px;
}
.actual-price {
  color: #888;
}
.trash {
  background: #fbdada;
  color: #ea3333 !important;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}
.grid-container1 {
  display: grid;
  grid-template-columns:
    2% 8% 14% 6% 1fr minmax(auto, 115px) minmax(auto, 115px) minmax(70px, 40%)
    0px;
  align-items: center;
  grid-gap: 10px;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  grid-auto-rows: minmax(10px, auto);
  grid-auto-columns: minmax(10px, auto);
}
.pending-pay {
  background: #fff5e8;
  color: #f99520;
}

.bg-export {
  color: #737272 !important;
  background: #f1f1f1;
}
.paid-applications {
  background: #2dcb74;
  padding: 10px;
  border-radius: 5px;
}
.paid-applications h6 {
  color: #fff;
}
.open-now {
  color: #2dcb74 !important;
  display: block;
}
.open label {
  display: block !important;
}
/*  */
.tracking-detail {
  padding: 3rem 0;
}
#tracking {
  margin-bottom: 1rem;
}
[class*='tracking-status-'] p {
  margin: 0;
  font-size: 1.1rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}
[class*='tracking-status-'] {
  padding: 1.6rem 0;
}
.tracking-list {
  border: 1px solid #e5e5e5;
}
.tracking-item {
  border-left: 4px solid var(--primary-color);
  position: relative;
  padding: 2rem 1.5rem 0.5rem 2.5rem;
  font-size: 0.9rem;
  margin-left: 3rem;
  min-height: 5rem;
}
.tracking-item:last-child {
  padding-bottom: 4rem;
}
.tracking-item .tracking-date {
  margin-bottom: 0.5rem;
}
.tracking-item .tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}
.tracking-item .tracking-content {
  padding: 0.5rem 0.8rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
}
.tracking-item .tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}
.tracking-item .tracking-icon {
  position: absolute;
  left: -0.7rem;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  background-color: #fff;
  color: #fff;
}

.tracking-item-pending {
  border-left: 4px solid #d6d6d6;
  position: relative;
  padding: 2rem 1.5rem 0.5rem 2.5rem;
  font-size: 0.9rem;
  margin-left: 3rem;
  min-height: 5rem;
}
.tracking-item-pending:last-child {
  padding-bottom: 4rem;
}
.tracking-item-pending .tracking-date {
  margin-bottom: 0.5rem;
}
.tracking-item-pending .tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}
.tracking-item-pending .tracking-content {
  padding: 0.5rem 0.8rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
}
.tracking-item-pending .tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}
.tracking-item-pending .tracking-icon {
  line-height: 2.6rem;
  position: absolute;
  left: -0.7rem;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  color: #d6d6d6;
}
.tracking-item-pending .tracking-content {
  font-weight: 600;
  font-size: 17px;
}

.tracking-item .tracking-icon.status-current {
  width: 1.9rem;
  height: 1.9rem;
  left: -1.1rem;
}
.tracking-item .tracking-icon.status-intransit {
  color: var(--primary-color);
  font-size: 0.6rem;
}
.tracking-item .tracking-icon.status-current {
  color: var(--primary-color);
  font-size: 0.6rem;
}
.main-application-content-flex {
  display: flex;
  align-items: flex-start;
  gap: 25px;
}
.single-university-logo img {
  height: 50px;
  object-fit: contain;
  width: auto;
}
.pending {
  background: #fff5e8;
  color: #f99520 !important;
}
.mr-2 {
  margin-right: 15px;
}
.badge-id-pay {
  margin-bottom: 10px;
}
.pre-payment-box {
  background: #f9f9f9;
  padding: 5px 10px;
  border-radius: 2px;
}
.pre-payment-heading h4 {
  font-size: 1rem;
  font-weight: 600;
}
.pre-payment-heading p {
  line-height: 20px;
  font-size: 0.8rem;
}
.pre-payment-heading {
  align-self: flex-start;
}
.pre-payment-heading-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box-flex {
  display: flex;
  gap: 45px;
  align-items: center;
}
.box-main-box i {
  margin-right: 8px;
}
.box-main-box {
  padding: 5px;
}
.color1 {
  background: #f7c8e0;
}
.color2 {
  background: #fdd36a;
}
.color3 {
  background: #b4e4ff;
}
.color4 {
  background: #dfffd8;
}
.pre-payment-card-required {
  border: 1px solid #f1f1f1;
  padding: 10px 10px;
  margin-bottom: 20px;

  overflow: hidden;
}
.icon-with-flex-pre-payment {
  display: flex;
  align-items: center;
  gap: 55px;
}
.pre-payment-heading h3 {
  font-size: 1.3rem;
  color: #888;
}

.pre-payment-heading h5 {
  margin-bottom: 10px;
  color: #222;
}
.compl i,
.compl span {
  color: #00d775;
}
.missing i,
.missing span {
  color: #df2e38;
}
.optional {
  background: #fff5e8;
  color: #f99520 !important;
}
.nostatus i,
.nostatus span {
  color: #999;
}
.ptb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.export-flex {
  display: flex;
  align-items: center;
}
.btn-generate {
  color: #fff;
  background: var(--third-color);
  border: 1px solid var(--third-color);
  width: 160px;
}
.btn-generate:hover {
  background: #bd9277;
  color: #fff;

  border: 1px solid var(--third-color);
}
.btn-generate i {
  margin-right: 10px;
}
.see-more {
  color: #14c6ef !important;
}
.for-student {
  flex-basis: 10% !important;
}

.tracking-item .tracking-content {
  font-weight: 600;
  font-size: 17px;
}

.application-full-requirement {
  border: 1px solid #e5e5e5;
  padding: 10px;
  border-radius: 3px;
  transition-property: width;
  transition-duration: 2s;
  transition-timing-function: linear;
  transition-delay: 8s;
}
.dummy-image img {
  width: 5em;
}
.university-name {
  flex: 10;
}
.university-name h3 {
  margin-bottom: 5px;
}
.location-map-single li {
  margin-bottom: 10px;
}
.dummy-image {
  height: 50px;
  flex-basis: 20%;
  width: auto;
  object-fit: contain;
}
.location-map-single {
  border-bottom: 1px solid #f1f1f1;
}
.location-map-single i {
  margin-right: 10px;
}
.location-map-single span {
  margin-right: 15px;
  font-size: 0.875rem;
  align-items: center;
  flex-wrap: nowrap;
  color: #888;
}
.university-name h3 {
  font-size: 1.4rem;
}
.location-map-single ul li {
  display: inline-flex;
  margin-right: 25px;
  font-size: 0.875rem;
  align-items: center;
  flex-wrap: nowrap;
  color: #888;
}
.location-map-single ul li i {
  margin-right: 10px;
}
.founded-year-single {
  margin: 10px 0px;
}
.main-founded {
  color: #222;
  font-weight: 600;
  font-size: 0.9rem;
}
.single-school-main-flex {
  display: flex;
  justify-content: space-between;
}
.school-details-box-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-heading-container {
  background: #14c6ef;
  border-radius: 2px;
  padding: 10px 20px;

  cursor: pointer;
}
.box-show-and-hide {
  margin-bottom: 20px;
}
.school-details-box-flex h3 {
  color: #fff;
  font-size: 1.2rem;
}
.school-details-box-flex i {
  color: #fff;
}
.application-full-requirement {
  margin-bottom: 20px;
}
.badge {
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}
.inclass {
  background: #e6f8eb;
  color: #00d775 !important;
}
.danger {
  background: #fbdada;
  color: #ea3333 !important;
}
.primary {
  background: #ecfafe;
  color: #14c6ef !important;
}
/* timeline */
.student-record .list-group-item.active {
  background: #fff5e8;
  color: #f99520;
}
/* end common class */
.student-record .top-status ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.student-record .top-status ul li {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 8px solid #ddd;
  box-shadow: 1px 1px 10px 1px #ddd inset;
  margin: 10px 5px;
}
.student-record .top-status ul li.active {
  border-color: #ffc107;
  box-shadow: 1px 1px 20px 1px #ffc107 inset;
}
/* end top status */

.student-record ul.timeline {
  list-style-type: none;
  position: relative;
}
.student-record .timeline li {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 5px;
}
.student-record ul.timeline:before {
  content: ' ';
  background: #14c6ef;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
.student-record ul.timeline > li {
  margin: 20px 0;
  padding-left: 100px;
}
.student-record ul.timeline > li:before {
  content: '\2713';
  background: #fff;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 0;
  left: 5px;
  width: 50px;
  height: 50px;
  z-index: 400;
  text-align: center;
  line-height: 50px;
  color: #d4d9df;
  font-size: 24px;
  border: 2px solid #d4d9df;
}
.student-record ul.timeline > li.active:before {
  content: '\2713';
  background: #14c6ef;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 0;
  left: 5px;
  width: 50px;
  height: 50px;
  z-index: 400;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 30px;
  border: 2px solid #14c6ef;
}
.success {
  background: #e6f8eb;
  color: #00d775 !important;
}
/* end timeline */
/* notes */
.container-notes {
  border: 1px solid #f1f1f1;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}
.container-notes img {
  height: 50px;
  width: auto;
  object-fit: contain;
}
/* Darker chat container-notes */
.darker {
  border-color: #eee;
  background-color: #f1f1f1;
}

/* Clear floats */
.container-notes::after {
  content: '';
  clear: both;
  display: table;
}

/* Style images */
.container-notes img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}

/* Style the right image */
.container-notes img.right {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}

/* Style time text */
.time-right {
  float: right;
  color: #aaa;
}

/* Style time text */
.time-left {
  float: left;
  color: #999;
}
/* notes form */
.notes-form {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
}
.container-notes-box {
  margin-bottom: 20px;
}
.drop_box {
  margin: 10px 0;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #a3a3a3;
  border-radius: 5px;
}
.filer-main-bo {
  border: 1px solid #f1f1f1;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.filter-btn {
  display: flex;
  height: 30px;
  margin-right: 10px;
  padding: 0px 10px;
  text-align: center;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(227, 233, 243);
  border-radius: 2px;
  line-height: 28px;
  font-size: 15px;
  font-weight: 500;
  font-family: Lato;
  -webkit-font-smoothing: antialiased;
}
.filter-btn i {
  margin-top: 7px;
  margin-right: 8px;
  color: #14c6ef;
}
.icon-box-filters i {
  color: #14c6ef;
  font-size: 0.9rem;
}
.icon-with-flex-filter {
  display: flex;
  align-items: center;
}
.flex-item2 {
  flex: 1;
}
.icon-box-filters {
  margin-right: 20px;
}
.ml {
  margin-left: 20px;
}
.icon-box-filters {
  border: 1px solid #f1f1f1;
  padding: 1px 10px;

  position: relative;
  top: -8px;
  border-radius: 2px;
}
.filter-button {
  margin-bottom: 20px;
}
/* wallet */
.wallet-section h4 {
  font-size: 1rem;
  color: #999;
}
.wallet h2 {
  color: #1b1b1b;
}
.wallet-section h5 {
  margin-bottom: 10px;
}
.search-container input {
  margin-left: 10px;
}
.filter-flex {
  padding: 20px 0px;
}
.oder-fl {
  display: flex;
  gap: 10px;
}
.from-flex {
  display: flex;
  align-items: center;
  gap: 20px;
}
.oder-fl {
  position: relative;
}
.search-container {
  display: flex;
  align-items: center;
  height: 90%;
}
.main-payment-box {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  padding: 20px;
  border-radius: 5px;
}
.main-table-heading h2 {
  color: #14c6ef !important;
}

.main-payment-box h2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #666;
  margin-bottom: 10px;
}
.main-table-heading {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px;
}
.content-payment h2 {
  font-size: 0.9rem;
}
.content-payment {
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
}
.box-display-flex {
  display: flex;
  justify-content: space-between;
}
.order-main {
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d9e9;
}
.order-main h6 {
  margin-bottom: 10px;
}
.box-open {
  padding: 10px 40px;
  background: #ecf2ff;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-bottom: 20px;
}
.box-open h6 {
  font-size: 0.9rem;
}
.order-details h6 {
  font-size: 0.7rem;
}
.wallet-image-flex {
  display: flex;
  align-items: center;
  gap: 15px;
}
.wallet-image-flex img {
  height: 40px;
  width: auto;
}
.wallet-image-flex h2 {
  font-size: 1.4rem;
}
.content-payment i {
  font-size: 1.3rem;
}
.wallet {
  padding: 10px;
  min-width: 150px;
  border-radius: 5px;
  width: 100%;
  max-width: 150px;
  border: 2px solid #f1f1f1;
}
.wallet h4 {
  margin-bottom: 5px;
}
.wallet-section p {
  margin-bottom: 10px;
  margin-top: 10px;
}
.view-receipt a {
  color: #14c6ef;
}
.receipt-box-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #d4d9e9;
}
.invoice-number :is(h4, p) {
  font-size: 0.9rem;
  margin-bottom: 10px;
  color: #111;
}
.receipt-box {
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  padding: 20px;
  background: #ecf2ff;
  border-radius: 5px;
  margin-bottom: 20px;
}
.item-details h2 {
  font-size: 1.5rem;
  border-bottom: 1px solid #d4d9e9;
  margin-bottom: 20px;
}
.item-details-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid #d4d9e9;
}
.item-details-flex :is(h4, p) {
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #555;
}
.item-total-price {
  text-align: end;
  padding-top: 10px;
}
.item-total-price h5 {
  color: #1b1b1b;
}
/* payout */
.payment-date-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payout-numbers-box {
  border: 1px solid #f1f1f1;
  padding: 10px 10px;
  border-radius: 5px;
}
.payout-when p {
  color: #626161;
  font-weight: 600;
  font-size: 1rem;
}
.payout-date p {
  color: #a8a8a8;
  font-weight: 600;
  font-size: 0.8rem;
}
.view-transactions a {
  color: #2a98ff;
  font-weight: 600;
}
.payout-amount h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}
.bor {
  border-bottom: 1px solid #f1f1f1;
  margin: 5px 0px;
}
.website-students-featured {
  width: 100%;
  height: auto;
  padding: 50px 0px;
}
.featured-content h3 {
  color: #363b51;
  font-family: 'Montserrat', Sans-serif;
  font-size: 34px;
  font-weight: 600;
  font-style: normal;
  line-height: 48px;
}
.featured-content p {
  text-align: left;
  color: #363b51;
  font-family: 'Open Sans', Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  /* padding: 10px 0px; */
}
.featured-para {
  padding: 20px 0px;
  margin-right: 12%;
}
.featured-img p {
  color: #363b51;
  font-family: 'Open Sans', Sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  line-height: 32px;
  text-align: center;
}
.process-steps-img {
  text-align: center;
}
.process-steps-img img {
  width: 100%;
  max-width: 85%;
  height: auto;
  border: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.connect-with-btn {
  padding: 40px 10px;
  text-align: center;
}
.connect-with-btn button {
  color: var(--white-color);
  background-color: #1e6deb;
  border-radius: 5px 5px 5px 5px;
  font-size: 16px;
  font-weight: 600;
}
.visa-cal-gradient {
  background-color: transparent;
  background-image: linear-gradient(-15deg, #1e6deb 0%, #003485 100%);
}
.popular-fields {
  width: 100%;
  background-color: #f6f8fe !important;
  margin-top: 30px;
  padding: 60px 0px;
}
.popular-fields-heading h2 {
  color: #363b51;
  font-family: 'Montserrat', Sans-serif;
  font-size: 34px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  padding: 40px 0px;
}
.main-icon-b img {
  height: 70px;
  width: auto;
}

.crm-user-profile-pic img {
  width: 100%;
  border-radius: 100%;
}
.notification-link {
  display: block;
  width: 100% !important;
  height: auto !important;
  position: static !important;
  background: none !important;
  box-shadow: none !important;
  border-radius: none !important;
}

.page-title h3 {
  text-decoration: underline;
}

.notification-show-more-btn p {
  color: #343434;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
